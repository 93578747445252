import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['page', 'errorMessage']
  }

  connect () {
    this.currentIndex = 0
  }

  get currentPage () {
    return this.pageTargets[this.currentIndex]
  }

  get currentError () {
    return this.currentPage.querySelector('[data-questionnaire-target="errorMessage"]')
  }

  showErrorMessage () {
    this.currentError.classList.remove('hidden')
  }

  hideErrorMessage () {
    this.currentError.classList.add('hidden')
  }

  showPrevious () {
    this.hideCurrentPage()
    this.currentIndex -= 1
    this.showCurrentPage()
  }

  showNext () {
    if (this.questionAnswered()) {
      this.hideErrorMessage()
      this.showNextQuestion()
    } else {
      this.showErrorMessage()
    }
  }

  validateCheckboxesAndShowNext () {
    if (this.allOptionsChecked()) {
      this.hideErrorMessage()
      this.showNextQuestion()
    } else {
      this.showErrorMessage()
    }
  }

  jumpToPage ({ params }) {
    this.hideCurrentPage()
    this.currentIndex = params.pageNumber
    this.showCurrentPage()
  }

  questionAnswered () {
    return this.currentPage.querySelector('input:checked')
  }

  allOptionsChecked () {
    return this.currentPage.querySelector('input:not(:checked)') === null
  }

  showNextQuestion () {
    this.hideCurrentPage()
    this.currentIndex += 1
    this.showCurrentPage()
  }

  hideCurrentPage () {
    this.currentPage.classList.add('hidden')
  }

  showCurrentPage () {
    this.currentPage.classList.remove('hidden')
  }
}
